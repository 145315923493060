@font-face {
  font-family: "Gotham";
  src: local("Gotham"), url('../fonts/Gotham-Book.ttf') format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"), url('../fonts/Gotham-Bold.ttf') format("truetype");
  font-weight: bold;
}


// Then add additional custom code here
body {
  font-family: Gotham, serif !important;
  color: var(--Gray-600) !important;
}

:root {
  --primary-color: #091D58;
  --primary-color-hover: #3A4A79;
  --secondary-color-hover: #C9DAF8;
  --secondary-color-hover-sublte: rgba(201, 218, 248, 0.12);
  --Light-Blue-140: #6C9DF2;
  --Neutrals-Dark-Grey: #86817B;
  --Gray-600: #475467;
}

$danger: #FF5020;
$warning: #FA9304;
$success: #0D9F73;

@import '~bootstrap/scss/bootstrap';

.btn {
  color: white;
  font-family: 'Gotham', serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  display: flex;
  padding: 0.625rem 1.125rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: none;
}

.btn-primary {
  background-color: var(--primary-color);
}

.btn-primary:hover {
  background-color: var(--primary-color-hover);
}

.btn-secondary {
  border-radius: 0.25rem;
  border: 1px solid var(--primary-color, #091D58);
  color: var(--primary-color, #091D58);
  font-weight: 500;
  background-color: transparent;
}

.btn-secondary:hover {
  border-radius: 0.25rem;
  background: var(--secondary-color-hover, #C9DAF8);
  border: 1px solid var(--secondary-color-hover, #C9DAF8);
  color: var(--primary-color, #091D58);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}

.label {
  padding: 0.375rem 1.5rem;
  align-items: center;
  gap: 0.625rem;
  font-family: 'Gotham', serif;
  border-radius: 0.5rem;
  background: var(--secondary-color-hover, #C9DAF8);
  color: var(--primary-color, #091D58);
  text-align: center;

  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.00375rem;
  text-transform: uppercase;
}

.label-hollow {
  border-radius: 62.5rem;
  border: 1px solid var(--Light-Blue-140, #6C9DF2);
  color: var(--Light-Blue-140, #6C9DF2);
  text-align: center;

  padding: 0.375rem 1.5rem;
  align-items: center;
  gap: 0.625rem;
  font-family: Gotham, serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 133.333% */
  letter-spacing: 0.00375rem;
  text-transform: uppercase;
}

.bold-heading {
  overflow: hidden;
  color: var(--primary-color, #091D58);
  text-overflow: ellipsis;

  /* Heading 3 */
  font-family: Gotham, serif;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem; /* 150% */
  letter-spacing: -0.015rem;
}

.text-primary {
  font-family: 'Gotham', serif;
  color: var(--primary-color, #091D58) !important;
}

.text-secondary {
  font-family: 'Gotham', serif;
  color: var(--Neutrals-Dark-Grey) !important;
}

h1 {
  font-size: 2.5rem;
}

.loginWrapper {
  min-height: 100vh;
}

.login-outer {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
}

.main-heading {
  margin-top: 50px;
}

.page-title {
  padding: 0 0 10px;
}

.btn-end {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 15px;
}

.plr36 {
  padding-left: 36px;
  padding-right: 36px;
}

.body-wrapper {
  position: relative;
  margin: 1rem;
  background-color: #fff;
  box-shadow: 0 9px 16px rgba(159, 162, 191, 0.18),
  0 2px 2px rgba(159, 162, 191, 0.32);
  border-radius: 10px;
}

.body-wrapper-not-found {
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 10px;
}

.table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
}

.page-header {
  max-width: 100% !important;
  padding: 0;
}

.common-table {
  border-radius: 10px 10px 0 0 !important;
}

img {
  max-width: 100%;
  height: inherit;
}

.MuiTableCell-root {
  white-space: nowrap;
}

.baitactive,
.baitinactive,
.baitnone {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  border-radius: 16px;
  vertical-align: middle;
  box-sizing: border-box;
  border: 0;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.baitVal {
  font-size: 14px;
}

.baitVal span {
  margin-left: 5px;
}

.baitnone {
  background-color: transparent;
  color: #000;
}

.baitactive {
  background-color: #539165;
}

.baitinactive {
  background-color: #ccc;
}

.change-pass-form {
  max-width: 600px;
  width: 90%;
}

button[type='submit'].MuiButtonBase-root {
  background-color: green;
}

.info-outer {
  display: inline-flex;
  align-items: center;
}

.info-outer.activeInfo {
  margin-right: 10px;
}

.info-outer span {
  padding-left: 5px;
}

button.MuiButtonBase-root.Mui-disabled {
  background-color: rgba(110, 117, 159, 0.1);
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  margin-top: 5px !important;
}

.table-pagination-inner {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 5px 15px;
  gap: 10px;
  flex-wrap: wrap;
}

select {
  border: 0;
  font-size: 14px;
  color: #2f4047;
  border-radius: 10px;
  padding: 5px 10px;
  background: #6e759f1a url('../../public/static/images/arrow.png') no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  background-size: 10px;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  outline: none;
  font-weight: 500;
}

.lang-dropdown {
  position: relative;
  cursor: pointer;
}

.lang-dropdown .MuiSvgIcon-root {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 20px;
}

.lang-dropdown select {
  height: 42px;
  margin-right: 10px;
  padding-left: 40px;
  cursor: pointer;
}

.not-found {
  text-align: center;
  padding: 0 15px;
  color: rgba(34, 51, 84, 0.7);
}

.bait-header {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
}

.bait-header span {
  margin-right: 5px;
}

.flex-start-item {
  justify-content: flex-start !important;
}

.detail-pest img {
  position: relative;
  top: 3px;
  margin-right: 4px;
}

.MuiButtonBase-root.loading-button,
.MuiButtonBase-root.loading-button:hover {
  background-color: #2f4047;
  color: #fff !important;
}

.loading-button:disabled {
  background-color: #f0f0f0 !important;
  color: #223354 !important;
}

.loading-button:disabled span {
  color: #223354 !important;
}

.box-detail {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.detail-header {
  gap: 20px;
}

.cus-info {
  display: flex;
  flex-direction: column !important;
  gap: 19px;
}

.cus-info h5 {
  margin: 0;
  font-size: 16px;
}

/* .cus-info span {
    font-size: 12px;
    font-weight: normal;
} */

.pt-0 {
  padding-top: 0 !important;
}

.password-reset {
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

@media screen and (max-width: 991px) and (orientation: landscape) {
  .loginWrapper {
    min-height: auto;
  }
}

@media screen and (max-width: 767px) {
  .body-wrapper {
    margin: 0 20px 20px;
    position: relative;
  }

  .MuiPageTitle-wrapper.pest-header {
    padding-left: 0;
    padding-right: 0;
  }

  .plr36 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .logs-first-col .filter-search-container.mt-10 {
    flex-wrap: wrap !important;
  }

  .logs-first-col .search-term-container {
    width: auto !important;
  }

  .filter-comment-buttons {
    flex-wrap: wrap;
    gap: 10px !important;
  }
}

@media screen and (max-width: 991px) {
  .schedule-maintenance-outer h1 {
    text-align: center;
  }

  .schedule-maintenance-outer h1,
  .schedule-maintenance-outer form {
    width: 100% !important;
  }

  .schedule-maintenance-outer form .MuiGrid-root,
  .schedule-maintenance-outer .schedule-wrapper {
    margin-left: 0;
    width: 100%;
  }
}

.pest-address {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
}

.pest-address h4 {
  margin: 0 0 5px 0;
  padding: 0;
}

/* .pest-address h5 {
    font-size: 12px;
} */
.pest-address .street-address {
  max-width: 300px;
  display: inline-block;
}

.MuiSvgIcon-root.green-status {
  fill: #42c54f;
}

.MuiSvgIcon-root.gray-status {
  fill: #858585;
}

.MuiSvgIcon-root.red-status {
  fill: #ff5020;
}

.MuiSvgIcon-root.yellow-status {
  fill: yellow;
}

.sidebar-logo {
  padding: 10px 2rem;
}

.switch-toggle .PrivateSwitchBase-input {
  left: -12px !important;
  width: 40px !important;
}

.switch-toggle .MuiSwitch-switchBase {
  padding: 7px !important;
}

table th {
  text-transform: capitalize !important;
}

.PhoneInputCountryIconImg {
  width: 20px !important;
  float: left;
  margin-right: 20px !important;
  margin-top: 15px !important;
}

.MuiDialogTitle-root.modal-heading {
  font-size: 18px;
}

.setPass .MuiInputBase-fullWidth {
  padding-right: 0 !important;
}

.common-table th svg {
  opacity: 0;
}

.common-table th .sort-btn svg {
  opacity: 1;
}

.common-table th:hover svg {
  opacity: 1;
}

.sort-btn {
  margin-left: 12px;
  display: flex;
  align-items: baseline;
}

.MuiTableCell-head:hover .table-sort {
  display: block;
}

.cur-pointer {
  cursor: pointer;
}

.tooltip-table td.tooltip-col {
  width: 250px;
  max-width: 250px;
}

.tooltip-table td .table-text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.textarea-mui {
  padding: 16.5px 14px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  width: 100%;
  border-radius: 10px;
  resize: none;
}

.textareaInput-error {
  border: 1px solid #ff1943;
}

.textarea-error {
  padding: 10px 0;
  word-break: break-all;
  color: #ff1943;
  font-size: 13px;
  font-weight: bold;
}

.editable-table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.editable-table-header {
  display: flex;
  background-color: rgba(34, 51, 84, 0.02);
  border-bottom: 1px solid rgba(34, 51, 84, 0.1);
  height: 50px;
}

.editable-header-cell,
.editable-table-cell {
  display: flex;
  flex: 1 1;
  font-weight: 100 !important;
  align-items: center;
  margin-right: 20px;
}

.editable-header-cell {
  font-size: 1.1rem;
  font-weight: 500 !important;
}

.multi-action-checkbox {
  max-width: 48px;
}

.editable-table.full-width-table .editable-header-cell:nth-child(1),
.editable-table.full-width-table .editable-table-cell:nth-child(1),
.editable-table.full-width-table .editable-header-cell:nth-child(2),
.editable-table.full-width-table .editable-table-cell:nth-child(2) {
  flex: 1;
}

.editable-table-cell input:focus,
.editable-table-cell input:focus-visible,
.editable-table-cell input:focus-within {
  border-color: transparent;
  outline: none;
}

.table-cell-position {
  position: relative;
  width: 100%;
}

.table-cell-position input {
  font-weight: 100 !important;
}

.table-cell-inputReq {
  position: absolute;
  bottom: 2px;
  left: 10px;
  font-size: 11px;
  color: #ff1943;
}

.slug,
.status {
  max-width: 100px;
}

.iccNumber {
  max-width: 220px;
}

.hoverable:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.uuid {
  display: block;
}

.editable-table-content {
  overflow-x: auto;
  overflow-y: auto;
}

.editable-table-row {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.editable-table-cell {
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
}

.editable-table-cell span input {
  padding: 0;
  border-radius: 5px;
  width: 100%;
  border: 0;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.editable-table-cell.clickable-div span.cur-pointer {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

input[type='checkbox'] {
  margin: 0 auto;
  width: 16px;
  height: 16px;
}

.editable-table-cell input.border1 {
  border: 1px solid #ccc;
  padding: 15px 10px;
}

.editable-table-cell input.border0 {
  border-color: transparent;
  padding: 15px 0;
  pointer-events: none;
}

.editable-table-cell input.border1.error {
  border-color: #ff1943;
}

.editable-table-cell button[type='submit'] {
  background-color: darkgreen;
  color: #fff;
}

.error-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background-color: #ffffff;
}

.error-modal ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.error-modal ul > li {
  color: red;
  min-height: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 50px 100px -20px,
  rgba(0, 0, 0, 0.3) 0 30px 60px -30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0.3rem;
}

.baitbox_action_icons {
  margin-right: 5px !important;
}

.table-head-new {
  width: 100%;
  display: flex !important;
}

.table-head-new .table-cell-wrap {
  width: 33.33%;
  flex: none !important;
}

.edit-body {
  width: 100% !important;
  display: flex !important;
  flex: none !important;
}

.clickable-div {
  cursor: pointer;
}

.body-cell {
  width: 33.33% !important;
  flex: none !important;
}

@media (max-width: 767px) {
  .editable-table-content {
    max-height: none;
  }

  .editable-header-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}

@media screen and (max-width: 991px) {
  .detail-header {
    flex-direction: column;
  }

  .pest-address .street-address {
    max-width: 100%;
  }

  .pest-address .cus-info:first-child {
    max-width: 100%;
  }
}

@media screen and (min-width: 1201px) {
  .pest-address {
    justify-content: flex-end;
  }
}

.header-row-wrap {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.search-term-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  max-width: 300px;
}

.search-term-container .form-control {
  height: 40px;
  padding: 5px 32px 5px 15px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid rgba(34, 51, 84, 0.5);
  color: #223354;
  outline: none;
  border-radius: 10px;
  font-size: 14px;
}

.search-term-container .form-control::placeholder {
  color: #223354;
}

.search-term-container button.MuiButtonBase-root {
  padding: 2px;
  min-width: auto;
  margin: 0;
  position: absolute;
  right: 10px;
}

.search-term-container button.MuiButtonBase-root span {
  margin: 0;
}

table th {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-action #demo-select-small-label[data-shrink='true'] {
  top: 9px;
}

#demo-select-small-label.MuiInputLabel-root.Mui-focused {
  top: 20px;
  font-size: 18px;
  color: rgba(34, 51, 84, 0.7);
}

.checkbox-action .MuiOutlinedInput-root {
  height: 40px;
}

.filter-search-container {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
}

.filter-outer button,
.filter-outer button:hover {
  height: 40px;
  font-size: 16px;
  background-color: #2f4047;
  color: #fff;
  margin-right: 0 !important;
}

.filter-outer button svg {
  font-size: 18px;
  margin-right: 5px;
}

.filter-table-head .editable-header-cell:nth-child(1),
.filter-table-body .editable-table-cell:nth-child(1) {
  flex: 0 0 200px;
}

.filter-table-head .editable-header-cell:nth-child(2),
.filter-table-body .editable-table-cell:nth-child(2) {
  flex: 0 0 240px;
}

.filter-table-head .editable-header-cell:nth-child(4),
.filter-table-body .editable-table-cell:nth-child(4) {
  flex: 0 0 200px;
}

.filter-table-head .editable-header-cell,
.filter-table-body .editable-table-cell {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.filter-table-body .editable-table-cell p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.MuiDialogContent-root.filter-modal {
  display: flex;
  padding-bottom: 0;
}

.filter-modal-head {
  flex: 1;
  padding: 0 0 10px 0;
  background-color: rgba(110, 117, 159, 0.2);
  min-width: 170px;
}

.filter-modal-head:nth-child(2) {
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
}

.filter-modal-head h1 {
  font-size: 16px;
  border-bottom: 2px solid #fff;
  padding: 0 14px 10px;
}

.filter-modal-head .filter-item {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  margin-bottom: 10px;
  padding: 0 10px;
}

.filter-modal-head .filter-item:last-child {
  margin-bottom: 0;
}

.filter-modal-btn {
  margin: 10px;
}

.MuiPageTitle-wrapper {
  margin-bottom: 0 !important;
}

.filter-item-name {
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
}

.not-allowed {
  cursor: not-allowed;
}

@media screen and (max-width: 615px) {
  .MuiDialogContent-root.filter-modal {
    flex-direction: column;
  }

  .filter-modal-head:nth-child(2) {
    border: 0;
    padding-bottom: 0;
  }
}

input[type='checkbox']:checked {
  /* accent-color: #539165;*/
}

input[type='checkbox']:disabled {
  cursor: not-allowed;
}

.filter-label-item {
  display: flex;
  gap: 5px;
  cursor: pointer;
}

.date-filter-logs {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.date-filter-logs label {
  font-size: 14px;
  padding-right: 10px;
}

.date-filter-logs input[type='date'] {
  height: 40px;
  padding: 5px 10px;
  background-color: #6e759f1a;
  border-radius: 10px;
  border: none;
  outline: none;
  width: 194px;
}

.date-filter-logs .apply-btn button {
  background-color: #539165;
}

.assign-tag-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  max-width: 300px;
}

@media screen and (max-width: 1780px) {
  .inventory-outer,
  .filter-container {
    overflow-x: auto;
    overflow-y: auto;
  }

  .inventory-outer .editable-table-header,
  .filter-container .filter-table-head {
    width: 1600px;
  }

  .inventory-outer .editable-table-content,
  .filter-container .filter-table-body {
    width: 1600px;
  }
}

.sim-unique-wrapper {
  max-width: 700px;
  width: 100%;
}

.sim-unique-id-outer {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
}

.sim-unique-id-outer:first-child {
  border-top: 1px solid #ccc;
}

.sim-unique-row {
  display: flex;
}

.sim-unique-row div {
  flex: 1;
  padding: 12px;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  min-width: 150px;
}

.add-bit-simId {
  position: relative;
}

.sim-unique-row div:last-child {
  border-left: 0;
}

.add-bit-simId .badge {
  display: inline-block;
  padding: 1px 10px;
  border-radius: 10px;
  background-color: green;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  position: absolute;
  top: 5px;
  margin-left: 5px;
}

.add-more-ids {
  background-color: #2f4047;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 8px 20px;
  line-height: 1.75;
  border: none;
  margin-top: 20px;
  cursor: pointer;
}

.sim-unique-iccNum,
.add-bit-uniqueId {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 50px);
  display: inline-block;
}

.auto-complete {
  width: calc(100% + 18px) !important;
  margin-left: -18px;
  margin-top: 15px;
}

.simulate-buttons {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}

.simulate-buttons-submit {
  display: flex;
  gap: 15px;
}

.date-time-picker input {
}

.schedule-wrapper {
  margin-top: 40px;
  width: calc(50% + 9px);
  margin-left: -9px;
}

.schedule-wrapper .body-wrapper {
  margin: 0;
}

.schedule-wrapper .body-wrapper .editable-header-cell:nth-child(1),
.schedule-wrapper .body-wrapper .editable-header-cell:nth-child(2) {
  flex: 1 1;
}

.schedule-wrapper .editable-table-content {
  overflow-x: auto;
  overflow-y: auto;
}

.schedule-wrapper .MuiTableCell-root {
  flex: 1 1;
  padding: 11px;
  text-align: left;
  display: flex;
  align-items: center;
  white-space: normal;
}

.schedule-wrapper .editable-table-content .MuiTableRow-root {
  display: flex;
}

.MuiClockPicker-root button.MuiButtonBase-root span.css-i6d3fz-MuiTypography-root {
  color: inherit;
}

.bait-checker-row-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.inner-row-value {
  display: flex;
  gap: 10px;
  align-items: center;
}

.inner-row-value * {
  font-weight: normal !important;
}

.inner-row-value h5 {
  margin: 0;
}

.bait-checker-row-item h3 {
  font-size: 16px;
  margin: 0;
}

.baitbox-icon-info {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 120px;
}

.baitbox-icon-info svg.signal-icon {
  width: 16px;
  height: 16px;
}

.baitbox-icon-info li {
  margin-right: 10px;
}

.baitbox-icon-info li:last-child {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mp-0 {
  margin: 0 0 20px 0 !important;
  padding: 0 !important;
}

.pR-60 {
  padding-right: 115px;
}

.current-detail-heading {
  margin: 0;
  font-size: 16px;
}

.inner-row-value li {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chart-outer {
  position: relative;
}

.chart-outer div:nth-child(2) {
  width: 100%;
}

.day-bait {
  text-align: left;
  font-size: 12px;
}

.day-heading {
  text-align: right;
  font-size: 12px;
  padding-right: 22px;
}

.inner-row-value li span {
  font-size: 12px;
}

@media screen and (max-width: 515px) {
  .css-6wcx0a-MuiResponsiveChart-container,
  .css-6wcx0a-MuiResponsiveChart-container svg {
    width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .bait-checker-row-item,
  .inner-row-value {
    flex-direction: column;
    align-items: flex-start;
  }

  .bait-checker-row-item {
    margin-bottom: 15px;
  }
}

.filter-comment-buttons {
  display: flex;
  gap: 21px;
}

.logs-first-col .filter-search-container button {
  font-weight: 400;
  font-size: 14px !important;
  width: 194px;
  height: 40px;
  border-radius: 10px;
}

.logs-first-col .filter-search-container {
  justify-content: space-between;
  margin-top: 0;
}

.logs-first-col .search-term-container {
  width: 100%;
  max-width: inherit;
  margin-right: 10px;
}

.logs-first-col .search-term-container .form-control {
  height: 40px;
}

.logs-first-col .filter-search-container.mt-10 {
  margin-top: 9px;
  flex-wrap: nowrap;
}

.pL-36 {
  padding: 0 36px;
}

.from-sec,
.to-sec {
  padding-right: 13px;
}

.to-sec label {
  padding-right: 20px;
}

.pRL-30 {
  padding: 0 31px;
}

.logs-first-col {
  display: flex;
  flex-direction: column !important;
  gap: 14px;
}

input,
button {
  font-family: 'Inter',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Helvetica,
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji' !important;
}

@media screen and (max-width: 767px) {
  .logs-first-col {
    gap: 0;
  }

  .pRL-30 {
    padding: 0;
  }

  .baitbox-icon-info {
    gap: 10px;
  }

  .cus-info {
    gap: 10px;
  }
}

.MuiBackdrop-root.MuiModal-backdrop {
  backdrop-filter: unset !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.search-dropdown {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.search-term-container.clear-search-btn button {
  width: 20px !important;
  height: 20px !important;
}

.checkbox-action fieldset {
  border: 1px solid rgba(34, 51, 84, 0.5);
}

.MuiMenu-list[aria-labelledby='demo-select-small-label'] li.MuiMenuItem-root {
  min-height: auto;
}

.MuiGrid-root.device-setting-inner-form {
  margin: 0;
  width: 100%;
}

.device-form {
  padding: 20px;
  width: 100%;
}

.device-form .textarea-error {
  padding: 0;
}

.configured-device-setting {
  margin-top: 0;
}

.configured-device-setting pre {
  white-space: pre-wrap;
  word-break: break-word;
}

.configured-device-setting h3 {
  margin-top: 0;
}

.configureVal {
  word-break: break-word;
  margin-bottom: 0;
}

.MuiAccordion-root.configure-device-accordion {
  background-color: transparent;
}

.MuiAccordion-root.configure-device-accordion .MuiAccordionSummary-root {
  padding: 0;
  min-height: inherit;
}

.MuiAccordion-root.configure-device-accordion h3,
.MuiAccordion-root.configure-device-accordion .body-wrapper,
.MuiAccordion-root.configure-device-accordion .MuiAccordionSummary-content {
  margin: 0;
}

.MuiAccordion-root.configure-device-accordion .MuiAccordionDetails-root {
  padding: 0;
}

.configure-device-accordion textarea {
  display: block;
}

.MuiDialog-container .device-form .MuiGrid-root.device-setting-inner-form {
  width: 600px;
  max-width: 100%;
}

.MuiDialog-container .device-form {
  padding: 20px 0;
  width: 100%;
}

.MuiDialog-container .device-form .MuiGrid-root.device-setting-inner-form textarea {
  min-height: 300px;
  margin: 0 0 20px;
}

.body-wrapper .device-form {
  padding: 20px;
}

svg {
  vertical-align: bottom !important;
}


.h1 {
  font-size: 3rem;
}

.h6 {
  font-size: 1.15rem;
  line-height: 1.08;
}

.hoverable:hover {
  background-color: var(--secondary-color-hover-sublte);
}

.rotating-circle {
  animation: rotate 2s linear infinite; /* Animation: rotate infinitely */
  transform-origin: center; /* Ensure the rotation is around the center */
}

@keyframes rotate {
  from {
    transform: rotate(0deg); /* Start at 0 degrees */
  }
  to {
    transform: rotate(360deg); /* Rotate fully */
  }
}
*:focus {
  outline: none;
}